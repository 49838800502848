<template>
  <div>
    <el-form
      :model="addvehiclesForm"
      ref="formedForm"
      :rules="rules"
      label-position="top"
      label-width="120px"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Государственный номер" prop="">
            <el-input v-model="addvehiclesForm.gosnumber">
              <template slot="prepend">№</template>
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="Масса" prop="">
            <el-input v-model="addvehiclesForm.mass">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-button type="success" @click="save">Сохранить</el-button>
    </el-form>
  </div>
</template>

<script>
import notification from "@/mixins/notification";

export default {
  name: "vehiclesAdd",
  mixins: [notification],
  data() {
    return {
      addvehiclesForm: {},
      file: {},
      rules: {
        name: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        number: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        date: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    changeFile(file, fileList) {
      console.log(fileList);
      this.file = file.raw;
    },

    save() {
      this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          let document = new FormData();
          document.append("gosnumber", this.addvehiclesForm.gosnumber);
          document.append(
            "mass",
            this.addvehiclesForm.mass
          );

          this.$store
            .dispatch("createvehicles", {gosnumber:this.addvehiclesForm.gosnumber,mass:this.addvehiclesForm.mass})
            .then((response) => {
              console.log(response);
              this.notification("Успешно", "Документ создан", "success");
              this.$store.dispatch("getAllvehicles", {
                page: 0,
                size: 20,
              });
              this.$store.dispatch("setShowModal", null);
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped></style>
