<template>
  <div>
    <h2>Справочник транспортных средств</h2>

    <vehicles-table></vehicles-table>
  </div>
</template>

<script>
import vehiclesTable from "@/components/ecologist/classifiers/vehicles/vehiclesTable";
export default {
  name: "vehiclesMain",
  components: { vehiclesTable },
};
</script>

<style scoped></style>
