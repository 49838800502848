<template>
  <div>
    <vehicles-main></vehicles-main>
  </div>
</template>

<script>
import vehiclesMain from "@/components/ecologist/classifiers/vehicles/vehiclesMain";
export default {
  name: "vehiclesMainView",
  components: { vehiclesMain },
};
</script>

<style scoped></style>
