<template>
  <div>
    <search-block title="транспортных средств">
      <el-row :gutter="10">
        <el-col :span="22">
          <el-input
            @input="searchDocument"
            v-model="search"
            placeholder="Поиск по наименованию"
          ></el-input>
        </el-col>
        <el-col :span="2">
          <el-button @click="showAddModal" type="success">Добавить</el-button>
        </el-col>
      </el-row>
    </search-block>
    <el-table stripe :data="listvehicles.content">
      <el-table-column label="Государственный номер" prop="name">
        <template slot-scope="item">
          {{ item.row.gosnumber }}
        </template>
      </el-table-column>
      <el-table-column label="Масса" prop="mass"></el-table-column>
      <el-table-column width="150" label="">
        <template slot-scope="item">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-button
                size="mini"
                style="width: 100%"
                @click="edit(item.row)"
                type="warning"
                >Редактировать</el-button
              >
              <el-button
                size="mini"
                @click="deleteDoc(item.row)"
                style="width: 100%; margin-top: 5px; margin-left: 0"
                type="danger"
                >Удалить</el-button
              >
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align: center"
      layout="total, sizes, prev, pager, next"
      background
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="listvehicles.size"
      :current-page.sync="listvehicles.number + 1"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :total="listvehicles.totalElements"
    >
    </el-pagination>

    <custom-modal
      title="Добавить транспортное средство"
      :visible="showModal === 'addvehicles'"
    >
      <vehicles-add></vehicles-add>
    </custom-modal>

    <custom-modal
      title="Изменить транспортное средство"
      :visible="showModal === 'updatevehicles'"
    >
      <vehicles-edit
        :selected-document="selectedDocument"
      ></vehicles-edit>
    </custom-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SearchBlock from "@/components/searchBlock";
import CustomModal from "@/components/customModal";
import vehiclesAdd from "@/components/ecologist/classifiers/vehicles/vehiclesAdd";
import notification from "@/mixins/notification";
import vehiclesEdit from "@/components/ecologist/classifiers/vehicles/vehiclesEdit";

export default {
  name: "vehiclesTable",
  components: {
    vehiclesEdit,
    vehiclesAdd,
    CustomModal,
    SearchBlock,
  },
  mixins: [notification],
  data() {
    return {
      search: null,
      selectedDocument: {},
    };
  },

  computed: {
    ...mapGetters({
      listvehicles: "GETLISTvehicles",
      showModal: "GETSHOWMODAL",
    }),
  },

  methods: {
    download(filePath) {
      console.log(filePath);
      this.$store.dispatch("downloadFile", filePath);
    },

    getFileName(filePath) {
      return filePath !== null
        ? filePath.slice(filePath.lastIndexOf("\\") + 1)
        : "Документ не загружен";
    },

    handleSizeChange(val) {
      this.getAllvehicles({ page: 0, size: val });
    },

    handleCurrentChange(val) {
      this.getAllvehicles({
        page: val - 1,
        size: this.listvehicles.size,
      });
    },

    showAddModal() {
      this.$store.dispatch("setShowModal", "addvehicles");
    },

    searchDocument(query) {
      this.$store.dispatch("searchvehicles", {
        query: query,
        page: 0,
        size: 20,
      });
    },

    edit(item) {
      console.log(item);
      this.selectedDocument = item;
      this.$store.dispatch("setShowModal", "updatevehicles");
    },

    deleteDoc(item) {
      this.confirm(
        "Удалить документ",
        "Вы действительно хотите удалить документ?",
        "warning",
        "Удалить",
        "Отмена"
      ).then(() => {
        this.$store
          .dispatch("deletevehicles", item.docId)
          .then((response) => {
            console.log(response);
            this.notification("Успешно", "Документ удален", "success");
            this.getAllvehicles({
              page: this.listvehicles.number,
              size: this.listvehicles.size,
            });
          });
      });
      console.log(item);
    },

    getAllvehicles(data) {
      this.$store.dispatch("getAllvehicles", data);
    },
  },

  mounted() {
    this.getAllvehicles({ page: 0, size: 20 });
  },
};
</script>

<style scoped></style>
